<template>
  <div id="app">
    <navBar></navBar>
    <particlesBackground></particlesBackground>
    <router-view/>
    <contactUsSection></contactUsSection>
  </div>
</template>

<script>
const navBar = () => import("./components/navBar.vue");
const particlesBackground  = () => import("./components/particlesBackground.vue");
const contactUsSection = () => import('./components/contactUsSection.vue');

export default {
  name: "App",

  data() {
    return {};
  },

  components: {
    navBar,
    particlesBackground,
    contactUsSection
    
  },
  mounted() {},
  methods: {},
};
</script>

<style>
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

html{
  width: 100vw;
    overflow-x: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav_bar {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
}

body {
  overflow-x: hidden !important;
  width: 100vw !important;
  background: black;
  background: linear-gradient(
    45deg,
    rgb(245, 245, 245) 0%,
    rgb(208, 208, 208) 50%,
    rgb(200, 200, 200) 100%
  );
}

</style>
