import Vue from "vue";
import App from "./App.vue";
import Particles from "particles.vue";


import "./assets/css/style.css";
import "atropos/css";
import router from "./router";
import "aos/dist/aos.css";
import AOS from "aos";
import Lenis from "@studio-freight/lenis";

Vue.config.productionTip = true;
Vue.use(Particles);
Vue.use(
  AOS.init({
    offset: 110,
    duration: 600,
    easing: "ease-in-out",
  })
);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

// Lenis setup
const lenis = new Lenis(
  {
    duration:1.5,
    easing:(t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    smoothWheel:true,
    smoothTouch:true,
  }
);

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

