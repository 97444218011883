<template>
  <div >
    <div id="landing_page">
      <atropos class="hero_img_atropos" :highlight="false" :shadow="false" :rotateTouch="false">
        <div
          id="heroImage"
          class="d-flex justify-content-center align-items-end"
        >
          <img data-aos="zoom-in"  src="../assets/ninjabot.webp" data-atropos-offset="0" />
          <h1 data-aos="slide-right"  data-aos-delay="100" data-atropos-offset="-10">Ninjabot</h1>
          <h2 data-aos="slide-left"  data-aos-offset="0" data-aos-delay="200" data-atropos-offset="10">AMR</h2>
        </div>
      </atropos>
      <div class="main_text text-center w-100">
        <h2 data-aos="fade-right" class="text-uppercase fs-2">Where Innovation Meets Autonomy</h2>
        <p data-aos="fade-left" class=" text-white-50 fs-6">
          Building Autonomous Robots and Cutting-Edge AI Solutions to
          Revolutionize Industries and Enhance Lives
        </p>
      </div>

      <div
        id="lp_social_icon_container"
        class="d-flex flex-column justify-content-between"
      > 
        <a data-aos="zoom-in-left"  href="https://www.instagram.com/botforge/" target="_blank"
          ><i class="bi bi-instagram" style="color: #d6d6d6;"></i></a>
        <a data-aos="zoom-in-left" data-aos-delay="300" href="https://twitter.com/BotforgeRobotix" target="_blank"
          ><i class="bi bi-twitter" style="color: #d6d6d6;"></i></a>
        <a data-aos="zoom-in-left" data-aos-delay="400" href="https://in.linkedin.com/company/botforge" target="_blank"
          ><i class="bi bi-linkedin" style="color: #d6d6d6;"></i></a>
        <a data-aos="zoom-in-left" data-aos-delay="500" href="https://gitlab.com/botforge-robotics" target="_blank"
          ><i class="bi bi-github" style="color: #d6d6d6;"></i></a>
      </div>
      <div
        id="lp_cont_arrow"
        class="text-center w-100"
        style="position: absolute; bottom: 3vh"
      >
        <a href="#aboutus">
          <i class="fas fa-angles-down fa-bounce pb-1" style="color: #d6d6d6; font-size: 30px;"></i>
        </a>
      </div>
    </div>
    <aboutUs></aboutUs>
    <servicesSection></servicesSection>
    <productsSection></productsSection>
    <ourTeam></ourTeam>
  </div>
</template>

<script>
const aboutUs= () => import("../components/aboutUsSection.vue");
const servicesSection = () => import("../components/servicesSection.vue");
const productsSection = () => import("../components/productsSection.vue");
const ourTeam = () => import('../components/ourTeam.vue');
const Atropos= () => import("atropos/vue");
export default {
  components: {
    aboutUs,
    servicesSection,
    productsSection,
    ourTeam,
    Atropos,
  }
}
</script>
<style >
#landing_page {
  width: 100%;
  height: 102vh;
}
#landing_page > .main_text {
  position: absolute;
  top: 15vh;
  color: white;
  opacity: 0.7;
  padding: 15px;
}
.atropos-inner {
  overflow: visible !important;
}
.hero_img_atropos {
  top: 38%;
}
#heroImage {
  width: 100%;
  position: relative;
  z-index: 0;
  top: 38%;
}
#heroImage > img {
  width: 52vw;
  max-width: 670px;
  min-width: 390px;
  margin-bottom: 15vh;
}
#heroImage > h1 {
  position: absolute;
  top: -12%;
  left: 10%;
  color: white;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 8vw;
  z-index: -1;
}
#heroImage > h2 {
  position: absolute;
  bottom: 25%;
  right: 18%;
  color: white;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 6.5vw;
  z-index: 1;
}
#lp_cont_arrow {
  z-index: 3;
}
#lp_social_icon_container {
  position: absolute;
  height: 170px;
  top: calc(50% - 70px);
  right: 4vw;
  z-index: 3;
}
#lp_social_icon_container>a>i{
  font-size: 20px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #heroImage {
    top: 65%;
  }
  .hero_img_atropos {
    top: 54%;
  }
  #heroImage > h1 {
    top: -12%;
    left: 6%;
    font-size: 13vw;
  }
  #heroImage > h2 {
    bottom: 33%;
    right: 1%;
    font-size: 10vw;
  }
}

@media only screen and (max-width: 992px) and (min-width: 600px) {
  #heroImage {
    top: 47%;
  }
  .hero_img_atropos {
    top: 47%;
  }
  #heroImage > h1 {
    font-size: 11vw;
    top: -15%;
    left: 6%;
  }
  #heroImage > h2 {
    bottom: 33%;
    right: 10%;
    font-size: 8vw;
  }
  #heroImage > img {
    width: 52vw;
  }
}
</style>